import { Dropdown } from "antd";
import { ELocalStorageKey } from "constants/enums";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import {
  IconArrowDown,
  IconUserHeader,
  IconLogoutV2,
  IconGroup,
  IconSetting,
} from "assets/icon";
import { useRole } from "utils/hooks/usePermissions";
import { useState } from "react";
import ModalEditSuccess from "components/ModalEditSuccess";
import { useAuth } from "utils/hooks/useAuth";
import ROUTES from "constants/routes";

export default function PageHeader() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const role = useRole();
  const authContext = useAuth();

  const [openModalEditSuccess, setOpenModalEditSuccess] =
    useState<boolean>(false);

  const onLogOut = (info?: any) => {
    authContext?.logout();
  };

  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={() => navigate(ROUTES.home.usersManager)}
          className={styles.viewBtnAction__changePassword}
        >
          <IconGroup className={styles.iconAction} />
          {t("nav.userManager")}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => navigate(ROUTES.home.setting)}
          className={styles.viewBtnAction__changePassword}
        >
          <IconSetting className={styles.iconAction} />
          {t("nav.basicSetting")}
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          onClick={onLogOut}
          className={styles.viewBtnAction__changePassword}
        >
          <IconLogoutV2 className={styles.iconAction__iconLock} />
          {t("common.logout")}
        </div>
      ),
    },
  ];

  return (
    <div className={styles.headerWrapper}>
      <div
        className={styles.viewNameStore}
        onClick={() => {
          navigate(ROUTES.home.index);
        }}
      >
        <div>{t("common.webName")}</div>
      </div>
      <div className={styles.actions}>
        <div className={styles.menuWrapper}>
          <div className={styles.menuItem}>{items.map((e) => e.label)}</div>
        </div>
      </div>
      <ModalEditSuccess
        title="パスワード変更完了"
        content="パスワードの変更が完了しました"
        open={openModalEditSuccess}
        toggle={() => {
          setOpenModalEditSuccess(!openModalEditSuccess);
        }}
        onSubmit={() => setOpenModalEditSuccess(false)}
      />
    </div>
  );
}
