import { CommonStatus } from "constants/enums";
import i18n from "i18n/i18n";

export const defaultTake = 10;

export const EXPIRES = 30;

export const TIME_TO_VERIFY_CODE = 120;

export const labels = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

export const LIMIT_SIZE_IMAGE = 1000000; // byte => 1 MB

export const acceptedImageType = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/heic",
  "image/heif",
];

export const optionChangePageSize = [
  { value: 10, label: "10 レコード" },
  { value: 20, label: "20 レコード" },
  { value: 50, label: "50 レコード" },
  { value: 100, label: "100 レコード" },
];

export const dataSetting = [
  {
    id: 0,
    title: "setting.autoObtainPrediction",
    key: "prediction_information_timing",
  },
  { id: 1, title: "setting.autoPurchase", key: "betting_timing" },
  { id: 2, title: "setting.telegram", key: "telegram_notification_timing" },
];
