export enum TokenType {
  TOKEN = "token",
  REFRESH_TOKEN = "refreshToken",
}

export enum ErrorCode {
  UNAUTHORIZED = "Unauthorized",
}

export enum ResponseStatus {
  NOT_FOUND = 404,
  FORBIDDEN = 403,
}

export enum DebounceTime {
  DEFAULT = 500,
}

export enum PermissionType {
  DASHBOARD_VIEW = "DASHBOARD_VIEW",
}

export enum UserPermissionType {
  USER_EDIT = 1,
  USER_VIEW = 2,
  MEMBER_EDIT = 4,
  MEMBER_VIEW = 5,
  COMMUNITY_EDIT = 16,
  COMMUNITY_VIEW = 17,
  COMMUNITY_BLOCK = 18,
  REPORT_EDIT = 19,
  REPORT_VIEW = 20,
  REPORT_BLOCK = 21,
  OFFER_EDIT = 22,
  OFFER_VIEW = 23,
  OFFER_BLOCK = 24,
  SWIPE_EDIT = 25,
  SWIPE_VIEW = 26,
  SWIPE_BLOCK = 27,
  NOTI_EDIT = 28,
  NOTI_VIEW = 29,
  NOTI_BLOCK = 30,
  RANKING_EDIT = 31,
  RANKING_VIEW = 32,
  RANKING_BLOCK = 33,
  REVIEW_EDIT = 34,
  REVIEW_VIEW = 35,
  REVIEW_BLOCK = 36,
  MASTER_DATA_EDIT = 37,
  MASTER_DATA_VIEW = 38,
  MASTER_DATA_BLOCK = 39,
}

export enum FormatTime {
  DATE_TIME = "DD/MM/YYYY HH:mm",
  DATE = "DD/MM/YYYY",
  DATE_REVERSE = "YYYY/MM/DD",
  DATE_JP = "YYYY年MM月DD日",
  DATE_TIME_JP = "YYYY年MM月DD日 HH:mm",
}

export enum LanguageType {
  JA = "ja",
  EN = "en",
  KEY = "cimode",
  VI = "vi",
  KO = "ko",
}

export enum LocalStorageCode {
  I18 = "i18nextLng",
}

export enum QueryKey {
  POST_LIST = "POST_LIST",
  POST_DETAIL = "POST_DETAIL",
  STAFF_LIST = "STAFF_LIST",
  PERMISSION_LIST = "PERMISSION_LIST",
  MEMBER_LIST = "MEMBER_LIST",
  POST_ADMIN_LIST = "POST_ADMIN_LIST",
  POST_ADMIN_DETAIL = "POST_ADMIN_DETAIL",
  NOTIFICATION_LIST = "NOTIFICATION_LIST",
  LIST_FAQ_MEMBER = "LIST_FAQ_MEMBER",
  LIST_FAQ_DOCTOR = "LIST_FAQ_DOCTOR",
  LIST_FAQ_CLINIC = "LIST_FAQ_CLINIC",
  NOTIFICATION_DETAIL = "NOTIFICATION_DETAIL",
  USER_LIST = "USER_LIST",
  POST_COMMENTS = "POST_COMMENTS",
  MANAGE_REPORT = "MANAGER_REPORT",
  MANAGE_BANNER = "MANAGER_BANNER",
  MANAGE_TERMS = "MANAGER_TERMS",
  MANAGE_CATEGORY = "MANAGER_CATEGORY",
  LIST_CAMPAIGNS = "LIST_CAMPAIGNS",
  DETAIL_MEMBER = "DETAIL_MEMBER",
  DETAIL_OFFER = "DETAIL_OFFER",
  LIST_REVIEW = "LIST_REVIEW",
  DETAIL_REVIEW = "DETAIL_REVIEW",
  SWIPE_LIST = "SWIPE_LIST",
  SWIPE_DETAIL = "SWIPE_DETAIL",
  MANAGE_POLICY = "MANAGE_POLICY",
  SETTING_VIEW_CATEGORY = "SETTING_VIEW_CATEGORY",
  CATEGORIES_PRODUCT = "CATEGORIES_PRODUCT",
  DETAIL_TERM = "DETAIL_TERM",
  DETAIL_POLICY = "DETAIL_POLICY",
  DETAIL_LAW = "DETAIL_LAW",
  DETAIL_FAQ = "DETAIL_FAQ",
  DETAIL_CONTACT = "DETAIL_CONTACT",
  REPLY_CONTACT = "REPLY_CONTACT",
  DETAIL_NOTIFY = "DETAIL_NOTIFY",
  LIST_NOTIFY = "LIST_NOTIFY",
}

export enum ActionType {
  ADD = "ADD",
  UPDATE = "UPDATE",
}

export enum ClientType {
  MEMBER = 1,
  ADMIN = 2,
}

export enum CommonStatus {
  ALL = 0,
  ACTIVE = 1,
  INACTIVE = 2,
}

export enum NotificationTypeSend {
  USER = 1,
  STORE = 2,
  SHIPPER = 3,
  ALL = 4,
  USER_SELECTED = 5,
}

export enum NotificationTimeSend {
  NOW = 1,
  SETTING = 2,
}

export enum ResourceType {}

export enum DATA_LEVEL {
  LEVEL_DEFAULT = 1,
  LEVEL_TWO = 2,
  LEVEL_THREE = 3,
  LEVEL_FOUR = 4,
}

export enum CommonValue {
  ALL = -1,
}

export const userTabsKey = {
  DETAIL: "0",
  OFFER: "1",
  REVIEW: "2",
};

export enum COMMON_STATUS {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum DetailRowType {
  STRING = "string",
  STATUS = "status",
}

export enum OfferImageType {
  BEFORE = 1,
  AFTER = 2,
}

export enum StorageType {
  LOCAL_STORAGE = "localStorage",
  SESSION_STORAGE = "sessionStorage",
}

export enum ModeRevenueDate {
  DAY = "day",
  MONTH = "month",
  YEAR = "year",
}

export enum ButtonType {
  DEFAULT = "default",
  OUTLINE = "outline",
  ACTION = "action",
  NONE_ROUND = "noneRound",
}

export enum DurationType {
  ALL = "all",
}

export enum DateOption {
  START_DATE = 1,
  END_DATE = 2,
}

export enum ActionModal {
  CREATE = "create",
  EDIT = "edit",
  VIEW = "view",
}

export enum GenderType {
  MALE = 1,
  FEMALE = 2,
}

export enum ELocalStorageKey {
  USERNAME = "username",
  ROLE_TYPE = "roleType",
}

export enum ESessionlStorageKey {
  TYPE_TAB_SHIPPER_LIST = "typeTabShipperList",
  MAIL_GET_CODE = "mailGetCode",
  CODE_OTP = "CODE_OTP",
}

export enum ROLE_TYPE {
  ADMIN = "1",
  STAFF = "2",
}

export enum TypeTabStaticPage {
  TERM = 0,
  POLICY = 1,
  COMMERCIAL_LAW = 2,
  FAQ = 3,
}

export enum SendContactType {
  USER = 1,
  ADMIN = 2,
}
