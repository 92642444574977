const ROUTES = {
  auth: {
    login: "/login",
  },
  home: {
    index: "/dashboard",
    setting: "/setting",
    usersManager: "/users-manager",
  },
};

export default ROUTES;
