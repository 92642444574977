import { Menu } from "antd";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { usePermissions, useRole } from "utils/hooks/usePermissions";
import useToggleSideNav from "utils/hooks/useToggleSideNav";
import styles from "./styles.module.scss";
import { IconPackage } from "assets/icon";
import { ELocalStorageKey, ROLE_TYPE } from "constants/enums";
import ModalDeleteItem from "components/ModalDeleteItem";
import { useAuth } from "utils/hooks/useAuth";

const getVisibleItem = (item: any) => {
  return item.filter((item: any) => {
    return item.visible;
  });
};

export default function SideNav() {
  const location = useLocation();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { collapsed } = useToggleSideNav();
  const [selectedKey, setSelectedKey] = useState("");
  const role = localStorage.getItem(ELocalStorageKey.ROLE_TYPE);
  const permissions = usePermissions();
  const [openModalLogOut, setOpenModalLogOut] = useState<boolean>(false);
  const authContext = useAuth();

  const items = useMemo(() => {
    return [
      {
        label: <Link to="/dashboard">{t("nav.dashboard")}</Link>,
        key: "dashboard",
        url: "/dashboard",
        visible: true,
        icon: <IconPackage />,
      },
    ];
  }, [t, permissions]);

  const onLogOut = (info: any) => {
    authContext?.logout();
  };

  useEffect(() => {
    items.forEach((item: any) => {
      if (location.pathname.startsWith(item.url || "###")) {
        setSelectedKey(item.key);
      }
      if (item.children) {
        item.children.forEach((childItem: any) => {
          if (location.pathname.startsWith(childItem.url || "###")) {
            setSelectedKey(childItem.key);
          }
        });
      }
    });
  }, [location.pathname]);

  useEffect(() => {
    const firstVisibleRoute: any = items.find((route) => route.visible);
    if (location.pathname === "/" && firstVisibleRoute?.visible) {
      if (firstVisibleRoute?.children) {
        const firstChildrenVisible = firstVisibleRoute?.children?.find(
          (route: any) => route.visible
        );
        navigate(firstChildrenVisible?.url);
      } else {
        navigate(firstVisibleRoute?.url);
      }
    }
  }, [location.pathname, permissions]);

  return <></>;

  return (
    <div
      className={classNames({
        [styles.sideNav]: true,
        [styles.sideNavCollapsed]: collapsed,
      })}
      style={{ width: collapsed ? 80 : 250, transition: "width 0.3s" }}
    >
      <Menu
        mode="inline"
        selectedKeys={[selectedKey]}
        defaultSelectedKeys={[]}
        className={styles.menu}
        items={getVisibleItem(items)}
      />
      <ModalDeleteItem
        open={openModalLogOut}
        dataItem={{}}
        toggle={() => {
          setOpenModalLogOut(!openModalLogOut);
        }}
        onSubmit={onLogOut}
        title={t("本当にログアウトしますか？")}
      />
    </div>
  );
}
