import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthWrapper from "wrappers/AuthWrapper";
import { useAuth } from "utils/hooks/useAuth";
import ROUTES from "constants/routes";

const Login = lazy(() => import("pages/Login"));
const Dashboard = lazy(() => import("pages/Dashboard"));
const Setting = lazy(() => import("pages/Setting"));
const UsersManager = lazy(() => import("pages/UsersManager"));

export default function RootWrapper() {
  const authContext = useAuth();
  const items = [
    {
      path: ROUTES.home.index,
      element: <Dashboard />,
    },
    {
      path: ROUTES.home.setting,
      element: <Setting />,
    },
    {
      path: ROUTES.home.usersManager,
      element: <UsersManager />,
    },
  ];

  return (
    <Routes>
      <Route
        path="/login"
        element={authContext?.token ? <Navigate to="/" replace /> : <Login />}
      />
      <Route path="/" element={<AuthWrapper />}>
        {items.map((e) => (
          <Route path={e.path} element={e.element} />
        ))}
      </Route>
    </Routes>
  );
}
